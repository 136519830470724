
import { PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelatorioReceita extends PageBase {
    service = new RelatorioService();
    item = new RelatorioGeralVendasModel();
    overlay: boolean = false;
    filter: any = {};

    mounted() {
        this.filter = this.$route.query;
        this.overlay = true;

        this.service.ListarRelatorioVendas(
            this.filter.empreendimentoIds, 
            this.filter.empresaIds, 
            this.filter.dataContratoInicial, 
            this.filter.dataContratoFinal, 
            this.filter.numeroContrato, 
            this.filter.clienteId, 
            this.filter.situacaoContratoId
        )
        .then(res => {
            this.item = res.data;
        })
        .catch(err => {
            if (!err.response) {
                this.$swal("Aviso", "Não foi possível acessar a API", "error");
            } else {
                this.$swal("Aviso", err.response.data, "error");
            }
        })
        .finally(() => {
            this.overlay = false;
        });
    }
}

class RelatorioGeralVendasModel {
    public relatorioVendasItensModels: RelatorioVendasItensModel[] = [];
    public totaisPago: number = 0;
    public totaisAberto: number = 0;
}

class RelatorioVendasItensModel {
    public empreendimentoNome: string = "";
    public grupoNome: string = "";
    public unidadeNome: string = "";
    public clienteNome: string = "";
    public clienteCPF: string = "";
    public clienteCEP: string = "";
    public clienteLogradouro: string = "";
    public clienteNumero: string = "";
    public clienteComplemento: string = "";
    public clienteBairro: string = "";
    public clienteMunicipio: string = "";
    public clienteUF: string = "";
    public clienteEmail: string = "";
    public clienteCelular: string = "";
    public numeroSequenciaContrato: string = "";
    public situacaoContrato: string = "";
    public dataContrato: Date = new Date();
    public dataUltimoPagamento: Date | null = null;
    public valorTotalPago: number = 0;
    public valorTotalAberto: number = 0;
}

